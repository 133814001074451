import { Injectable, inject } from '@angular/core';
import { CommonRepositoryAbstract } from '@freddy/common';
import { User } from '@freddy/models';
import { Firestore } from '@angular/fire/firestore';
import { OrganizationRepository } from './organization.repository';

@Injectable({
  providedIn: 'root',
})
export class UserRepository extends CommonRepositoryAbstract<User> {
  static DOC_PATH = 'users';

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  getDocPath(): string {
    return `${OrganizationRepository.DOC_PATH}/:organizationId/${UserRepository.DOC_PATH}`;
  }
}
