import { FirebaseStorageService, TranslatedForm } from '@freddy/common';
import { Locale } from 'locale-enum';
import { FormOf } from '@freddy/common';
import { Asset, TranslatedContent } from '@freddy/models';

export class DataMapper {
  static mapTranslatedFormToContent<T>(
    translatedForm: TranslatedForm<T>,
  ): TranslatedContent<T> {
    return Object.keys(translatedForm).reduce(
      (previousValue, currentValue, currentIndex) => {
        return {
          ...previousValue,
          [currentValue]: translatedForm?.[currentValue as Locale]
            ?.model as TranslatedForm<T>,
        };
      },
      {},
    );
  }

  static mapTranslatedContentToForm<T>(
    translatedContent: TranslatedContent<T>,
  ): TranslatedForm<T> {
    return (Object.keys(translatedContent) as Locale[]).reduce<
      TranslatedForm<T>
    >((previousValue: TranslatedForm<T>, currentValue) => {
      previousValue[currentValue] = DataMapper.getFormOf<T>(
        translatedContent[currentValue] as T,
      );
      return previousValue;
    }, {});
  }

  static async addAssetsToTranslatedContent<T, R extends Record<keyof R, any>>(
    translatedContent: TranslatedContent<T>,
    assets: TranslatedContent<Asset[]>,
    propertyToAdd: keyof R,
    storageService: FirebaseStorageService,
  ): Promise<TranslatedContent<R>> {
    const updatedTranslatedContent: TranslatedContent<R> = {};

    for (const locale of Object.keys(translatedContent) as Locale[]) {
      const contentAssets = assets[locale] ?? [];
      const resolvedAssets = await Promise.all(
        contentAssets.map((asset: Asset) => storageService.getFile(asset.path)),
      );

      updatedTranslatedContent[locale] = {
        ...translatedContent[locale],
        [propertyToAdd]: resolvedAssets,
      } as R;
    }

    return updatedTranslatedContent;
  }

  static getFormOf<T>(model: T): FormOf<T> {
    return {
      dirty: false,
      status: '',
      errors: {},
      model: {
        ...model,
      },
    };
  }
}
