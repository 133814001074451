import { Organization } from '@freddy/models';
import { QueryParam } from '@freddy/common';

export class SaveOrganizationAction {
  static readonly type = '[Organization] Save the organization';

  constructor() {}
}

export class ListOrganizationsAction {
  static readonly type = '[Organization] List organizations';

  constructor(public readonly query: QueryParam) {}
}

export class EditOrganizationAction {
  static readonly type = '[Organization] Edit organization';

  constructor(public readonly organization: Organization) {}
}

export class CloneOrganizationAction {
  static readonly type = '[Organization] Clone organization';

  constructor(public readonly organization: Organization) {}
}

export class DeleteOrganizationAction {
  static readonly type = '[Organization] Delete organization';

  constructor(public readonly organization: Organization) {}
}

export class FetchAndEditOrganizationAction {
  static readonly type = '[Organization] Fetch organization';

  constructor(public readonly organizationUid: string) {}
}

export class ResetOrganizationFormAction {
  static readonly type = '[Organization] Reset organization form';

  constructor() {}
}

export class ListUsersAction {
  static readonly type = '[Organization] List users';

  constructor(
    public readonly organization: Organization,
    public readonly query: QueryParam,
  ) {}
}

export class GiveAccessAction {
  static readonly type = '[Organization] Give access';

  constructor(
    public readonly userId: string,
    public readonly organizationId: string,
  ) {}
}
