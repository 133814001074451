import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import { Locale } from 'locale-enum';
import { FormOf, PaginatedResult } from '@freddy/common';
import { patch } from '@ngxs/store/operators';
import {
  CloneScenarioAction,
  DeleteScenarioAction,
  DeleteTempMarker,
  EditScenarioAction,
  FetchAndEditScenarioAction,
  GenerateRoutesForTeam,
  ListScenariosAction,
  NewScenarioAction,
  NextScenarioPageAction,
  PrevScenarioPageAction,
  ResetScenarioFormAction,
  SaveScenarioAction,
  SearchScenariosAction,
  SearchScenariosFailureAction,
  SearchScenariosSuccessAction,
  SetDefaultMissionsOrder,
  UpdateMissionOrder,
} from '../actions/scenario.actions';
import { ScenarioRepository } from '../repository/scenario.repository';
import { Navigate } from '@ngxs/router-plugin';
import { MissionState } from '../../mission/store/mission.store';
import { ChallengeState } from '../../challenge/store/challenge.store';
import { DataMapper } from '../../../core/utils/data.mapper';
import { Scenario, ScenarioLocation } from '@freddy/models';
import { MissionsService } from '../services/missions.service';
import {
  catchError,
  finalize,
  firstValueFrom,
  from,
  switchMap,
  throwError,
} from 'rxjs';
import { tap } from 'rxjs/operators';
import { DeleteChallengeAction } from '../../challenge/actions/challenge.actions';
import { DeleteMissionAction } from '../../mission/actions/mission.actions';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { TranslateService } from '@ngx-translate/core';
import { HotToastService } from '@ngneat/hot-toast';
import { environment } from '../../../../environments/environment';
import { SearchService } from '../../../core/services/search.service';

export const SCENARIO_STATE_TOKEN = new StateToken<ScenarioStateModel>(
  'scenario',
);

export interface ScenarioCommonAttributesForm {
  scenarioName: string;
  description: string;
  languagesAvailable: Locale[];
}

export interface ScenarioLocationForm {
  location: ScenarioLocation;
}

export interface ScenarioMissionsOrderForm {
  missionsOrders: string[][];
}

export interface ScenarioStateModel {
  uid?: string;
  layout: {
    loading: boolean;
  };
  list: PaginatedResult<Scenario>;
  scenarioCommonAttributesForm: FormOf<ScenarioCommonAttributesForm>;
  scenarioLocationForm: FormOf<ScenarioLocationForm>;
  scenarioMissionsOrderForm: ScenarioMissionsOrderForm;
  searchTerm: string;
  isSearchActive: boolean;
  totalSearchResults: number;
}

const defaultFormValue = {
  uid: undefined,
  layout: {
    loading: false,
  },
  list: {
    currentPage: 0,
    items: [],
    prevDisabled: true,
    nextDisabled: false,
  },
  scenarioCommonAttributesForm: {
    model: {
      scenarioName: '',
      description: '',
      languagesAvailable: [],
    },
    dirty: false,
    status: '',
    errors: {},
  },
  scenarioLocationForm: {
    model: {
      location: {
        center: {
          lon: 4.351697,
          lat: 50.846557,
        },
        name: '',
        unusedMarkers: [],
      },
    },
    dirty: false,
    status: '',
    errors: {},
  },
  scenarioMissionsOrderForm: {
    missionsOrders: [],
  },
  searchTerm: '',
  isSearchActive: false,
  totalSearchResults: 0,
};

@State<ScenarioStateModel>({
  name: SCENARIO_STATE_TOKEN,
  defaults: {
    ...defaultFormValue,
  },
})
@Injectable()
export class ScenarioState {
  private readonly scenarioRepository = inject(ScenarioRepository);
  private readonly missionsService = inject(MissionsService);
  private readonly translateService = inject(TranslateService);
  private readonly toastService = inject(HotToastService);
  private readonly searchService = inject(SearchService);
  private readonly store = inject(Store);

  @Selector()
  static isSearchActive(state: ScenarioStateModel): boolean {
    return state.isSearchActive;
  }

  @Selector()
  static searchTerm(state: ScenarioStateModel): string {
    return state.searchTerm;
  }

  @Selector([ScenarioState])
  static uid(state: ScenarioStateModel): string | undefined {
    return state.uid;
  }

  @Selector([ScenarioState])
  static missionsOrder(state: ScenarioStateModel): string[][] {
    return state.scenarioMissionsOrderForm.missionsOrders;
  }

  @Selector([ScenarioState])
  static scenarioLocationForm(
    state: ScenarioStateModel,
  ): ScenarioStateModel['scenarioLocationForm'] {
    return state.scenarioLocationForm;
  }

  @Selector([ScenarioState])
  static listOfScenarios(state: ScenarioStateModel): PaginatedResult<Scenario> {
    return state.list;
  }

  @Selector([ScenarioState])
  static isLoading(state: ScenarioStateModel): boolean {
    return state.layout.loading;
  }

  @Selector([ScenarioState])
  static scenarioCommonAttributesForm(
    state: ScenarioStateModel,
  ): FormOf<ScenarioCommonAttributesForm> {
    return state.scenarioCommonAttributesForm;
  }

  @Selector()
  static languagesAvailable(state: ScenarioStateModel): Locale[] {
    return state.scenarioCommonAttributesForm.model?.languagesAvailable ?? [];
  }

  @Selector()
  static scenarioLocation(
    state: ScenarioStateModel,
  ): ScenarioLocation | undefined {
    return state.scenarioLocationForm.model?.location;
  }

  @Action(EditScenarioAction)
  async editScenario(
    ctx: StateContext<ScenarioStateModel>,
    action: EditScenarioAction,
  ) {
    ctx.patchState({
      scenarioLocationForm: DataMapper.getFormOf({
        location: action.scenario.location,
      }),
      scenarioCommonAttributesForm: DataMapper.getFormOf(
        action.scenario.commonAttributes,
      ),
      uid: action.scenario.uid,
    });
    if (action.scenario.missionsOrders) {
      ctx.patchState({
        scenarioMissionsOrderForm: {
          missionsOrders: JSON.parse(action.scenario.missionsOrders),
        },
      });
    }
  }

  @Action(FetchAndEditScenarioAction)
  async fetchScenario(
    ctx: StateContext<ScenarioStateModel>,
    action: FetchAndEditScenarioAction,
  ) {
    return this.scenarioRepository.get(action.scenarioUid).pipe(
      tap((scenario) => {
        if (scenario) {
          this.store.dispatch(new EditScenarioAction(scenario));
        }
      }),
    );
  }

  @Action(CloneScenarioAction)
  cloneScenario(
    ctx: StateContext<ScenarioStateModel>,
    action: CloneScenarioAction,
  ) {
    const loadingMessage = this.translateService.instant(
      'scenario.cloning.inProgress',
    );
    const successMessage = this.translateService.instant(
      'scenario.cloning.success',
    );
    const errorMessage = this.translateService.instant(
      'scenario.cloning.error',
    );

    const toast = this.toastService.loading(loadingMessage, {
      autoClose: false,
      duration: Infinity,
    });

    return from(this.scenarioRepository.cloneScenario(action.scenario)).pipe(
      switchMap((clonedScenario) => {
        toast.updateMessage(successMessage);
        toast.updateToast({ type: 'success' });
        return ctx.dispatch([
          new EditScenarioAction(clonedScenario),
          new Navigate(['/scenario/edit', clonedScenario.uid]),
        ]);
      }),
      catchError((error) => {
        console.error('Error cloning scenario:', error);
        toast.updateMessage(errorMessage);
        toast.updateToast({ type: 'error' });
        return throwError(() => new Error('Failed to clone scenario'));
      }),
      finalize(() => {
        setTimeout(() => toast.close(), 3000); // Close the toast after 3 seconds
      }),
    );
  }

  @Action(DeleteScenarioAction)
  async deleteScenario(
    ctx: StateContext<ScenarioStateModel>,
    action: DeleteScenarioAction,
  ) {
    action.scenario.challenges.forEach((challenge) => {
      this.store.dispatch(new DeleteChallengeAction(challenge, true));
    });

    action.scenario.missions.forEach((mission) => {
      this.store.dispatch(new DeleteMissionAction(mission, true));
    });

    await firstValueFrom(this.scenarioRepository.delete(action.scenario));
    ctx.setState(
      patch({
        list: patch({
          items: [
            ...ctx
              .getState()
              .list.items.filter((i) => i.uid !== action.scenario.uid),
          ],
        }),
      }),
    );
    if (ctx.getState().list.items.length > 0) {
      // TODO: Should refetch if no items in the list
    }
  }

  @Action(SaveScenarioAction)
  async saveScenario(
    ctx: StateContext<ScenarioStateModel>,
    action: SaveScenarioAction,
  ) {
    const state = ctx.getState();
    const location = state.scenarioLocationForm.model;
    const commonAttributes = state.scenarioCommonAttributesForm.model;
    if (!commonAttributes?.scenarioName) {
      return;
    }
    const missions = this.store.selectSnapshot(MissionState.missions);
    const challenges = this.store.selectSnapshot(ChallengeState.challenges);
    if (commonAttributes && location) {
      let uid;
      if (state.uid) {
        uid = state.uid;
        await this.scenarioRepository.updateScenario({
          uid: uid,
          status: 'PUBLISHED',
          scenarioLocationForm: location,
          scenarioCommonAttributesForm: commonAttributes,
          scenarioMissionsOrderForm: state.scenarioMissionsOrderForm,
          missions: missions,
          challenges: challenges.items,
        });
      } else {
        const scenario = await this.scenarioRepository.createScenario({
          status: 'PUBLISHED',
          scenarioLocationForm: location,
          scenarioCommonAttributesForm: commonAttributes,
          scenarioMissionsOrderForm: state.scenarioMissionsOrderForm,
          missions: missions,
          challenges: challenges.items,
        });
        uid = scenario!.uid;
      }
      if (!action.silently) {
        ctx.patchState(defaultFormValue);
        this.store.dispatch(new Navigate(['/scenario']));
      } else {
        ctx.patchState({
          uid: uid,
        });
      }
      this.toastService.success(
        this.translateService.instant('scenario.toast.scenarioSaved'),
      );
    }
  }

  @Action(ResetScenarioFormAction)
  async resetScenarioForm(ctx: StateContext<ScenarioStateModel>) {
    ctx.patchState({
      ...defaultFormValue,
    });
  }

  @Action(ListScenariosAction)
  async listScenarios(
    ctx: StateContext<ScenarioStateModel>,
    action: ListScenariosAction,
  ) {
    const state = ctx.getState();

    ctx.patchState({
      layout: {
        loading: true,
      },
      isSearchActive: false,
    });

    return this.scenarioRepository
      .list({
        ...action.query,
        currentPage: state.list.currentPage,
      })
      .pipe(
        tap((paginatedResult) => {
          ctx.patchState({
            list: paginatedResult,
            layout: { loading: false },
          });
        }),
      );
  }

  @Action(SearchScenariosAction)
  searchScenarios(
    ctx: StateContext<ScenarioStateModel>,
    action: SearchScenariosAction,
  ) {
    ctx.patchState({
      layout: { loading: true },
      isSearchActive: true,
      searchTerm: action.searchTerm,
    });
    return this.searchService
      .search<Scenario>(environment.typesense.scenariosCollectionName, {
        q: action.searchTerm,
        query_by:
          'commonAttributes.scenarioName,commonAttributes.description,location.name',
        page: action.page,
        per_page: action.pageSize,
      })
      .pipe(
        tap((result) => {
          ctx.dispatch(
            new SearchScenariosSuccessAction(
              result.hits?.map((hit) => hit.document) ?? [],
              result.found ?? 0,
            ),
          );
        }),
        catchError((error) => {
          ctx.patchState({ layout: { loading: false } });
          return ctx.dispatch(new SearchScenariosFailureAction(error));
        }),
      );
  }

  @Action(SearchScenariosSuccessAction)
  searchScenariosSuccess(
    ctx: StateContext<ScenarioStateModel>,
    action: SearchScenariosSuccessAction,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      list: {
        items: action.scenarios,
        nextDisabled: action.scenarios.length < state.list.items.length,
        prevDisabled: state.list.currentPage === 1,
        currentPage: state.list.currentPage,
      },
      totalSearchResults: action.totalResults,
      layout: { loading: false },
    });
  }

  @Action(SearchScenariosFailureAction)
  searchScenariosFailure(
    ctx: StateContext<ScenarioStateModel>,
    action: SearchScenariosFailureAction,
  ) {
    console.error('Search failed:', action.error);
    this.toastService.error(
      this.translateService.instant('scenario.search.error'),
    );
  }

  @Action(NextScenarioPageAction)
  nextPage(ctx: StateContext<ScenarioStateModel>) {
    const state = ctx.getState();
    if (state.isSearchActive) {
      const nextPage = state.list.currentPage + 1;
      ctx.patchState({ list: { ...state.list, currentPage: nextPage } });
      ctx.dispatch(
        new SearchScenariosAction(
          state.searchTerm,
          nextPage,
          state.list.items.length,
        ),
      );
    } else {
      ctx.dispatch(
        new ListScenariosAction({
          startAfter: state.list.lastItem,
          orderBy: 'createdAt',
          limit: state.list.items.length,
        }),
      );
    }
  }

  @Action(PrevScenarioPageAction)
  prevPage(ctx: StateContext<ScenarioStateModel>) {
    const state = ctx.getState();
    if (state.isSearchActive && state.list.currentPage > 1) {
      const prevPage = state.list.currentPage - 1;
      ctx.patchState({ list: { ...state.list, currentPage: prevPage } });
      ctx.dispatch(
        new SearchScenariosAction(
          state.searchTerm,
          prevPage,
          state.list.items.length,
        ),
      );
    } else if (!state.isSearchActive && state.list.firstItem) {
      // Use Firebase pagination
      ctx.dispatch(
        new ListScenariosAction({
          endBefore: state.list.firstItem,
          orderBy: 'createdAt',
          limit: state.list.items.length,
        }),
      );
    }
  }

  @Action(SetDefaultMissionsOrder)
  async setDefaultMissionsOrder(ctx: StateContext<ScenarioStateModel>) {
    const scenarioUid = ctx.getState().uid;
    if (scenarioUid) {
      const scenario = await firstValueFrom(
        this.scenarioRepository.get(scenarioUid),
      );
      const missionsOrders = this.missionsService.getScenarioRouting(
        scenario,
        30,
      );
      ctx.patchState({
        scenarioMissionsOrderForm: {
          missionsOrders,
        },
      });
    }
  }

  @Action(GenerateRoutesForTeam)
  async generateRoutesForTeam(
    ctx: StateContext<ScenarioStateModel>,
    action: GenerateRoutesForTeam,
  ) {
    const scenarioUid = ctx.getState().uid;
    if (scenarioUid) {
      const scenario = await firstValueFrom(
        this.scenarioRepository.get(scenarioUid),
      );
      const missionsOrders =
        this.missionsService.getScenarioRoutingForASingleTeam(scenario);
      ctx.patchState({
        scenarioMissionsOrderForm: {
          missionsOrders: ctx
            .getState()
            .scenarioMissionsOrderForm.missionsOrders.map((order, index) => {
              if (index === action.index) {
                return missionsOrders;
              }
              return order;
            }),
        },
      });
    }
  }

  @Action(UpdateMissionOrder)
  updateMissionOrder(
    ctx: StateContext<ScenarioStateModel>,
    action: UpdateMissionOrder,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      scenarioMissionsOrderForm: {
        missionsOrders: state.scenarioMissionsOrderForm.missionsOrders.map(
          (order, index) => {
            if (index === action.index) {
              return action.missionsOrder;
            }
            return order;
          },
        ),
      },
    });
    this.store.dispatch(new SaveScenarioAction(true));
  }

  @Action(DeleteTempMarker)
  deleteTempMarker(
    ctx: StateContext<ScenarioStateModel>,
    action: DeleteTempMarker,
  ) {
    const state = ctx.getState();
    ctx.dispatch(
      new UpdateFormValue({
        path: 'scenario.scenarioLocationForm',
        value:
          state.scenarioLocationForm.model?.location.unusedMarkers?.filter(
            (marker) =>
              marker.geoPoint.lat !== action.geoPoint.lat &&
              marker.geoPoint.lon !== action.geoPoint.lon,
          ) ?? [],
        propertyPath: 'location.unusedMarkers',
      }),
    );
  }

  @Action(NewScenarioAction)
  newScenario(ctx: StateContext<ScenarioStateModel>) {
    ctx.dispatch([
      new ResetScenarioFormAction(),
      new Navigate(['/scenario/new']),
    ]);
  }
}
