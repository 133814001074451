import { Injectable, inject } from '@angular/core';
import { CommonRepositoryAbstract, PathParams, WithUid } from '@freddy/common';
import {
  ScenarioCommonAttributesForm,
  ScenarioLocationForm,
  ScenarioMissionsOrderForm,
} from '../store/scenario.store';
import { GuidUtils } from '../../../core/utils/guid.utils';
import { TenantService } from '../../../core/auth/services/tenant.service';
import { MissionsService } from '../services/missions.service';
import { Challenge, Mission, Scenario, Status } from '@freddy/models';
import { Firestore } from '@angular/fire/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import { MissionRepository } from '../../mission/repository/mission.repository';
import { ChallengeRepository } from '../../challenge/repository/challenge.repository';
import { RepositoryOptions } from '../../../../../../common/src/lib';

@Injectable({
  providedIn: 'root',
})
export class ScenarioRepository extends CommonRepositoryAbstract<Scenario> {
  private readonly tenantService = inject(TenantService);
  private readonly missionsService = inject(MissionsService);
  private readonly missionRepository = inject(MissionRepository);
  private readonly challengeRepository = inject(ChallengeRepository);

  static DOC_PATH = 'scenarios';
  static PAGE_SIZE = 10;

  constructor() {
    const firestore = inject(Firestore);
    super(firestore);
  }

  getDocPath(): string {
    return `${this.tenantService.getOrganizationPrefixPath()}${ScenarioRepository.DOC_PATH}`;
  }

  override create(
    documentData: WithUid<Scenario>,
    pathParams?: PathParams,
  ): Observable<Scenario> {
    documentData.organization = this.tenantService.currentOrganizationSlug;
    return super.create(documentData, pathParams);
  }

  async createScenario(formDataScenario: {
    status: Status;
    scenarioCommonAttributesForm: ScenarioCommonAttributesForm;
    scenarioLocationForm: ScenarioLocationForm;
    scenarioMissionsOrderForm: ScenarioMissionsOrderForm;
    missions: Mission[];
    challenges: Challenge[];
  }): Promise<Scenario> {
    const scenario: Scenario = {
      ...this.mapper(formDataScenario),
    };
    return firstValueFrom(
      this.create({
        ...scenario,
        missionsOrders: JSON.stringify(
          this.missionsService.getScenarioRouting(scenario, 30),
        ),
      }),
    );
  }

  async cloneScenario(scenarioToClone: Scenario): Promise<Scenario> {
    const newScenarioId = GuidUtils.generateUuid();

    const [clonedMissions, clonedChallenges] = await Promise.all([
      Promise.all(
        scenarioToClone.missions.map((m) =>
          this.missionRepository.cloneMission(m),
        ),
      ),
      Promise.all(
        scenarioToClone.challenges.map((c) =>
          this.challengeRepository.cloneChallenge(c),
        ),
      ),
    ]);

    // Update missionsOrders with new mission UIDs
    const updatedMissionsOrders = this.updateMissionsOrders(
      scenarioToClone.missionsOrders,
      scenarioToClone.missions,
      clonedMissions,
    );

    // Create a new scenario object with cloned data
    const clonedScenario: Scenario = {
      ...scenarioToClone,
      externalId: undefined,
      commonAttributes: {
        ...scenarioToClone.commonAttributes,
        scenarioName: `${scenarioToClone.commonAttributes.scenarioName} - Cloned`,
      },
      uid: newScenarioId,
      missions: clonedMissions,
      challenges: clonedChallenges,
      missionsOrders: updatedMissionsOrders,
    };

    return firstValueFrom(this.create(clonedScenario));
  }

  async updateScenario(formDataScenario: {
    uid: string;
    status: Status;
    scenarioCommonAttributesForm: ScenarioCommonAttributesForm;
    scenarioLocationForm: ScenarioLocationForm;
    scenarioMissionsOrderForm: ScenarioMissionsOrderForm;
    missions: Mission[];
    challenges: Challenge[];
  }): Promise<void> {
    const scenario = this.mapper(formDataScenario);
    return firstValueFrom(
      this.update({
        ...scenario,
        organization: this.tenantService.currentOrganizationSlug,
      }),
    );
  }

  override getRepositoryOptions(): RepositoryOptions {
    return {
      pageSize: ScenarioRepository.PAGE_SIZE,
    };
  }

  private mapper(formDataScenario: {
    uid?: string;
    scenarioCommonAttributesForm: ScenarioCommonAttributesForm;
    scenarioLocationForm: ScenarioLocationForm;
    scenarioMissionsOrderForm: ScenarioMissionsOrderForm;
    missions: Mission[];
    challenges: Challenge[];
    status: Status;
  }): Scenario {
    this.updateMissionsPresence(
      formDataScenario.scenarioMissionsOrderForm,
      formDataScenario.missions
        .filter((mission) => mission.common.geolocalized)
        .map((mission) => mission.uid),
    );

    return {
      status: formDataScenario.status,
      uid: formDataScenario.uid ?? GuidUtils.generateUuid(),
      location: {
        ...formDataScenario.scenarioLocationForm.location,
      },
      commonAttributes: {
        ...formDataScenario.scenarioCommonAttributesForm,
        languagesAvailable:
          formDataScenario.scenarioCommonAttributesForm.languagesAvailable,
      },
      missionsOrders: JSON.stringify(
        formDataScenario.scenarioMissionsOrderForm.missionsOrders,
      ),
      updatedAt: new Date(),
      missions: formDataScenario.missions,
      challenges: formDataScenario.challenges,
    };
  }

  private updateMissionsPresence(
    formData: ScenarioMissionsOrderForm,
    allMissionUids: string[],
  ): void {
    formData.missionsOrders = formData.missionsOrders.map((missionsOrder) => {
      const updatedMissionsOrder = allMissionUids
        .filter((uid) => !missionsOrder.includes(uid))
        .concat(missionsOrder);

      return [
        ...new Set(
          updatedMissionsOrder.filter((uid) => allMissionUids.includes(uid)),
        ),
      ];
    });
  }

  private updateMissionsOrders(
    originalMissionsOrders: string,
    originalMissions: Mission[],
    clonedMissions: Mission[],
  ): string {
    const missionsOrdersArray: string[][] = JSON.parse(originalMissionsOrders);
    const missionUidMap = new Map(
      originalMissions.map((origMission, index) => [
        origMission.uid,
        clonedMissions[index].uid,
      ]),
    );

    const updatedMissionsOrders: string[][] = missionsOrdersArray.map((order) =>
      order.map((missionUid) => missionUidMap.get(missionUid) || missionUid),
    );

    return JSON.stringify(updatedMissionsOrders);
  }
}
