import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TenantService } from '../services/tenant.service';

@Injectable({ providedIn: 'root' })
export class TenantRedirectGuard {
  private router = inject(Router);
  private tenantService = inject(TenantService);


  canActivate() {
    console.log('TenantRedirectGuard.canActivate');

    if (this.tenantService.currentOrganizationSlug) {
      return this.router.navigate([
        this.tenantService.currentOrganizationSlug,
        'auth',
        'sign-in',
      ]);
    }
    this.router.navigate(['public', 'auth', 'sign-in']);
    return false;
  }
}
