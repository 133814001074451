import { Injectable, inject } from '@angular/core';
import { CommonRepositoryAbstract, FirebaseUtils } from '@freddy/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirebaseChatMessage } from '@freddy/models';
import { GameRepository } from '../../game/repository/game.repository';
import { ChatRepository } from './chat.repository';
import { TenantService } from '../../../core/auth/services/tenant.service';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class ChatMessageRepository extends CommonRepositoryAbstract<FirebaseChatMessage> {
  private readonly tenantService = inject(TenantService);

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  public getMessages(
    gameUid: string,
    chatUid: string,
  ): Observable<FirebaseChatMessage[]> {
    return this.getCollectionsChanges({
      gameUid,
      chatUid,
    }).pipe(
      map((messages) =>
        messages.map((message) => FirebaseUtils.convertDate(message)),
      ),
      map((messages) =>
        messages.sort((a, b) => {
          // @ts-ignore
          return a.createdAt - b.createdAt;
        }),
      ),
    );
  }

  getDocPath(): string {
    return (
      this.tenantService.getOrganizationPrefixPath() +
      `${GameRepository.DOC_PATH}/:gameUid/${ChatRepository.DOC_PATH}/:chatUid/messages`
    );
  }
}
