import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { FormOf, PaginatedResult } from '@freddy/common';
import { inject, Injectable } from '@angular/core';
import { OrganizationRepository } from '../repository/organization.repository';
import { patch } from '@ngxs/store/operators';
import {
  DeleteOrganizationAction,
  GiveAccessAction,
  ListOrganizationsAction,
  ListUsersAction,
  ResetOrganizationFormAction,
  SaveOrganizationAction,
} from '../actions/organization.action';
import { Navigate } from '@ngxs/router-plugin';
import { Organization, User } from '@freddy/models';
import { UserRepository } from '../repository/user.repository';
import { tap } from 'rxjs/operators';
import { AuthenticationState } from '../../../core/auth/store/authentication.store';
import { where } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { catchError, throwError } from 'rxjs';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from '../../../core/auth/services/tenant.service';

export const ORGANIZATION_STATE_TOKEN = new StateToken<OrganizationStateModel>(
  'organization',
);

export interface OrganizationForm {
  uid?: string;
  organizationSlug: string;
  organizationName: string;
  taxId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: any;
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  country: string;
}

export interface OrganizationStateModel {
  layout: {
    loading: boolean;
  };
  list: PaginatedResult<Organization>;
  entityForm: FormOf<OrganizationForm>;
  userList: PaginatedResult<User>;
}

const defaultFormValue = {
  layout: {
    loading: false,
  },
  entityForm: {
    dirty: false,
    status: '',
    errors: {},
  },
  list: {
    currentPage: 0,
    items: [],
    prevDisabled: true,
    nextDisabled: false,
  },
  userList: {
    currentPage: 0,
    items: [],
    prevDisabled: true,
    nextDisabled: false,
  },
};

@State<OrganizationStateModel>({
  name: ORGANIZATION_STATE_TOKEN,
  defaults: {
    ...defaultFormValue,
  },
})
@Injectable()
export class OrganizationState {
  private readonly store = inject(Store);
  private readonly organizationRepository = inject(OrganizationRepository);
  private readonly userRepository = inject(UserRepository);
  private functions = inject(Functions);
  private toastService = inject(HotToastService);
  private translate = inject(TranslateService);
  private tenantService = inject(TenantService);

  @Selector([OrganizationState])
  static listOfOrganizations(
    state: OrganizationStateModel,
  ): PaginatedResult<Organization> {
    return state.list;
  }

  @Selector([OrganizationState])
  static listOfUsers(state: OrganizationStateModel): PaginatedResult<User> {
    return state.userList;
  }

  @Selector([OrganizationState])
  static isLoading(state: OrganizationStateModel): boolean {
    return state.layout.loading;
  }

  @Action(ResetOrganizationFormAction)
  async resetGameForm(ctx: StateContext<OrganizationStateModel>) {
    ctx.patchState(defaultFormValue);
  }

  @Action(SaveOrganizationAction)
  saveOrganization(ctx: StateContext<OrganizationStateModel>) {
    const entity = ctx.getState().entityForm?.model;

    if (!entity) {
      throw new Error('Organization entity is required');
    }

    // Extract common organization data
    const organizationData = {
      ...entity,
      phone: entity.phone?.internationalNumber,
    };

    // Create observable based on operation type
    const saveOrUpdateOperation$ = entity.uid
      ? this.organizationRepository.updateOrganization(organizationData)
      : this.organizationRepository.create({
          ...organizationData,
          uid: entity.organizationSlug,
          parentOrganizationId:
            this.store.selectSnapshot(AuthenticationState.user)?.organization ??
            null,
        });

    // Return the observable chain
    return saveOrUpdateOperation$.pipe(
      // @ts-ignore
      tap(() => {
        this.store.dispatch([
          new ResetOrganizationFormAction(),
          new Navigate(['/organization']),
        ]);
      }),
    );
  }

  @Action(ListOrganizationsAction)
  async listOrganizations(
    ctx: StateContext<OrganizationStateModel>,
    action: ListOrganizationsAction,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      layout: {
        loading: true,
      },
    });

    let query = action.query;

    if (this.store.selectSnapshot(AuthenticationState.isPartner)) {
      query = {
        ...query,
        where: [
          where(
            'parentOrganizationId',
            '==',
            this.store.selectSnapshot(AuthenticationState.user)?.organization,
          ),
        ],
      };
    }

    return this.organizationRepository
      .list({ ...query, currentPage: state.list.currentPage })
      .pipe(
        tap((paginatedResult) => {
          ctx.setState(
            patch<OrganizationStateModel>({
              list: paginatedResult,
              layout: patch({
                loading: false,
              }),
            }),
          );
        }),
      );
  }

  @Action(ListUsersAction)
  async listUsers(
    ctx: StateContext<OrganizationStateModel>,
    action: ListUsersAction,
  ) {
    ctx.patchState({
      layout: {
        loading: true,
      },
    });

    return this.userRepository
      .list(
        {
          ...action.query,
          currentPage: ctx.getState().userList.currentPage,
        },
        {
          organizationId: action.organization.organizationSlug,
        },
      )
      .pipe(
        tap((paginatedResult) => {
          ctx.setState(
            patch<OrganizationStateModel>({
              userList: paginatedResult,
              layout: patch({
                loading: false,
              }),
            }),
          );
        }),
      );
  }

  @Action(DeleteOrganizationAction)
  deleteOrganizationAction(
    ctx: StateContext<OrganizationStateModel>,
    action: DeleteOrganizationAction,
  ) {
    if (action.organization.uid)
      this.organizationRepository.delete(action.organization);
    this.store.dispatch(
      new ListOrganizationsAction({
        orderBy: 'createdAt',
      }),
    );
  }

  @Action(GiveAccessAction)
  giveAccessAction(
    ctx: StateContext<OrganizationStateModel>,
    action: GiveAccessAction,
  ) {
    const toast = this.toastService.loading(
      this.translate.instant('organization.listUserComponent.toast.ongoing'),
    );
    const organizationId =
      action.organizationId ?? this.tenantService.currentOrganizationSlug;
    if (!organizationId) {
      throw new Error('Organization id is mandatory');
    }
    const callable = httpsCallable<
      { organizationId: string; userId: string },
      void
    >(this.functions, 'grantorganizationclaim');
    return fromPromise(
      callable({
        organizationId: organizationId,
        userId: action.userId,
      }),
    ).pipe(
      tap((res) => {
        toast.close();
        this.toastService.success(
          this.translate.instant(
            'organization.listUserComponent.toast.userCanConnect',
          ),
        );
      }),
      catchError((err) => {
        toast.close();
        this.toastService.error(
          this.translate.instant('organization.listUserComponent.toast.error'),
        );
        return throwError(() => err);
      }),
    );
  }
}
