import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import {
  ChallengeCommonAttributes,
  ChallengeFormDataType,
  ChallengeIntroForm,
  FirebaseStorageService,
  FormOf,
  MissionIntroForm,
  PaginatedResult,
  TranslatedForm,
} from '@freddy/common';
import {
  ChallengeCreatedAction,
  ChallengeUpdatedAction,
  DeleteChallengeAction,
  DeleteChallengesAction,
  EditChallengeAction,
  ImportChallengesAction,
  ListChallengesAction,
  NewChallengeAction,
  ResetChallengeAction,
  SaveChallengeAction,
  SaveScenarioChallengeAction,
} from '../actions/challenge.actions';
import { ChallengeRepository } from '../repository/challenge.repository';

import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import {
  EditScenarioAction,
  ResetScenarioFormAction,
  SaveScenarioAction,
} from '../../scenario/actions/scenario.actions';
import { DataMapper } from '../../../core/utils/data.mapper';
import {
  Challenge,
  ChallengeMetadata,
  MissionIntro,
  MissionTypeEnum,
  TranslatedContent,
} from '@freddy/models';
import {
  catchError,
  EMPTY,
  firstValueFrom,
  from,
  mergeMap,
  of,
  toArray,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AssetService } from '../../../core/assets/asset.service';
import { RouterUtils } from '../../../core/utils/router.utils';
import { Router } from '@angular/router';
import { AssetRepository } from '../../../core/assets/asset.repository';
import { HotToastService } from '@ngneat/hot-toast';
import { GuidUtils } from '../../../core/utils/guid.utils';
import { ComboboxItem } from '../../../shared/components/inputs/combobox-input/combobox-input.component';
import { CollectionRepository } from '../../collection/repository/collection.repository';
import { ResetMissionFormAction } from '../../mission/actions/mission.actions';

export const CHALLENGE_STATE_TOKEN = new StateToken<ChallengeStateModel>(
  'newChallenge',
);

export interface ChallengeStateModel {
  layout: {
    loading: boolean;
  };
  list: PaginatedResult<Challenge>;
  uid?: string;
  common: FormOf<ChallengeCommonAttributes>;
  intro: TranslatedForm<ChallengeIntroForm>;
  metadata: FormOf<ChallengeMetadata>;
  collection: FormOf<{
    collection: ComboboxItem | null;
  }>;
}

const defaultValue = {
  uid: undefined,
  layout: {
    loading: false,
  },
  common: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
  intro: {},
  metadata: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
  collection: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
};

@State<ChallengeStateModel>({
  name: CHALLENGE_STATE_TOKEN,
  defaults: {
    ...defaultValue,
    list: {
      currentPage: 0,
      items: [],
      prevDisabled: true,
      nextDisabled: false,
    },
  },
})
@Injectable()
export class ChallengeState {
  private readonly assetService = inject(AssetService);
  private readonly storageService = inject(FirebaseStorageService);
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly challengeRepository = inject(ChallengeRepository);
  private readonly assetRepository = inject(AssetRepository);
  private readonly toastService = inject(HotToastService);
  private readonly collectionRepository = inject(CollectionRepository);

  @Selector()
  static missionType(state: ChallengeStateModel): MissionTypeEnum | undefined {
    return state.metadata.model?.missionType;
  }

  @Selector([ChallengeState])
  static challenges(state: ChallengeStateModel): PaginatedResult<Challenge> {
    return state.list;
  }

  @Selector()
  static isLoading(state: ChallengeStateModel): boolean {
    return state.layout.loading;
  }

  @Action(NewChallengeAction)
  newChallenge(ctx: StateContext<ChallengeStateModel>) {
    this.resetChallengeForm(ctx);
    this.router.navigate(['new'], {
      relativeTo: RouterUtils.getActivatedRoute(this.router),
    });
  }

  @Action(ResetScenarioFormAction)
  async resetScenarioForm(ctx: StateContext<ChallengeStateModel>) {
    ctx.setState(
      patch<ChallengeStateModel>({
        list: patch({
          items: [],
        }),
      }),
    );
  }

  @Action(SaveChallengeAction)
  async saveChallenge(
    ctx: StateContext<ChallengeStateModel>,
    action: SaveChallengeAction,
  ) {
    const state = ctx.getState();
    this.setLoadingState(ctx, true);

    const { common, collection, metadata, intro, uid } = state;
    if (!common.model || !metadata.model) return;

    try {
      const challengeData: ChallengeFormDataType = {
        uid,
        common: common.model,
        intro: DataMapper.mapTranslatedFormToContent(intro),
        metadata: metadata.model,
        collectionIds: [collection.model?.collection?.id].filter(
          (d) => !!d,
        ) as string[],
      };

      const challenge = uid
        ? await this.challengeRepository.updateChallenge({
            ...challengeData,
            uid,
          })
        : await this.challengeRepository.createChallenge(challengeData);

      this.store.dispatch([
        uid
          ? new ChallengeUpdatedAction(challenge)
          : new ChallengeCreatedAction(challenge),
        new ResetChallengeAction(),
      ]);
    } catch (error) {
      // Handle errors appropriately
      console.error('Error saving challenge:', error);
    } finally {
      this.store.dispatch(new ResetChallengeAction());
      this.setLoadingState(ctx, false);
    }
  }

  @Action(SaveScenarioChallengeAction)
  async saveScenarioChallengeAction(
    ctx: StateContext<ChallengeStateModel>,
    action: SaveScenarioChallengeAction,
  ) {
    const state = ctx.getState();
    this.setLoadingState(ctx, true);

    const { common, collection, metadata, intro, uid } = state;
    if (!common.model || !metadata.model) return;

    try {
      const challengeData: ChallengeFormDataType = {
        uid,
        common: common.model,
        intro: DataMapper.mapTranslatedFormToContent(intro),
        metadata: metadata.model,
        collectionIds: [collection.model?.collection?.id].filter(
          (d) => !!d,
        ) as string[],
      };

      const challenge =
        await this.challengeRepository.updateChallengeAssets(challengeData);

      this.store.dispatch([
        uid
          ? new ChallengeUpdatedAction(challenge)
          : new ChallengeCreatedAction(challenge),
        new ResetChallengeAction(),
      ]);
    } catch (error) {
      // Handle errors appropriately
      console.error('Error saving challenge:', error);
    } finally {
      this.setLoadingState(ctx, false);
      this.store.dispatch(new ResetChallengeAction());
    }
    return;
  }

  @Action(ListChallengesAction)
  async listChallenges(
    ctx: StateContext<ChallengeStateModel>,
    action: ListChallengesAction,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      layout: {
        loading: true,
      },
    });

    return this.challengeRepository
      .list({ ...action.query, currentPage: ctx.getState().list.currentPage })
      .pipe(
        tap((paginatedResult) => {
          ctx.setState(
            patch<ChallengeStateModel>({
              list: {
                ...paginatedResult,
                lastItem:
                  paginatedResult.currentPage > 1 &&
                  paginatedResult.items.length === 0
                    ? state.list.lastItem
                    : paginatedResult.lastItem,
                firstItem:
                  paginatedResult.currentPage > 1 &&
                  paginatedResult.items.length === 0
                    ? state.list.firstItem
                    : paginatedResult.firstItem,
              },
              layout: patch({
                loading: false,
              }),
            }),
          );
        }),
      );
  }

  @Action(ResetChallengeAction)
  resetChallengeForm(ctx: StateContext<ChallengeStateModel>) {
    ctx.patchState(defaultValue);
  }

  @Action(ChallengeUpdatedAction)
  challengeUpdated(
    ctx: StateContext<ChallengeStateModel>,
    action: ChallengeUpdatedAction,
  ) {
    ctx.setState(
      patch<ChallengeStateModel>({
        list: patch({
          items: updateItem<Challenge>(
            (challenge) => challenge?.uid === action.challenge.uid,
            action.challenge,
          ),
        }),
      }),
    );
    ctx.dispatch(new SaveScenarioAction(true));
  }

  @Action(ChallengeCreatedAction)
  challengeCreated(
    ctx: StateContext<ChallengeStateModel>,
    action: ChallengeCreatedAction,
  ) {
    ctx.setState(
      patch<ChallengeStateModel>({
        list: patch({
          items: append([action.challenge]),
        }),
      }),
    );
    ctx.dispatch(new SaveScenarioAction(true));
  }

  @Action(EditScenarioAction)
  async editScenario(
    ctx: StateContext<ChallengeStateModel>,
    action: EditScenarioAction,
  ) {
    ctx.setState(
      patch<ChallengeStateModel>({
        list: patch({
          items: action.scenario.challenges,
        }),
      }),
    );
  }

  @Action(EditChallengeAction)
  async editChallenge(
    ctx: StateContext<ChallengeStateModel>,
    action: EditChallengeAction,
  ) {
    const state = ctx.getState();
    this.resetChallengeForm(ctx);
    ctx.patchState({
      layout: {
        ...state.layout,
        loading: true,
      },
    });
    const assets = await firstValueFrom(
      this.assetService.getIntroAssets(action.challenge).pipe(
        map((assets) => {
          return this.assetService.getTranslatedAssets(assets);
        }),
      ),
    );

    const challengeIntroForm: TranslatedContent<ChallengeIntroForm> =
      await DataMapper.addAssetsToTranslatedContent<
        MissionIntro,
        MissionIntroForm
      >(action.challenge.intro, assets, 'images', this.storageService);

    let collectionForm;
    const collectionUid = action.challenge.collectionIds?.[0];
    if (collectionUid) {
      try {
        const collection = await firstValueFrom(
          this.collectionRepository.get(
            action.challenge.collectionIds?.[0] ?? '',
          ),
        );
        collectionForm = DataMapper.getFormOf({
          collection: {
            id: collection.uid,
            label: collection.name,
            item: collection,
          },
        });
      } catch (error) {
        console.error('Error getting collection:', error);
      }
    }

    ctx.patchState({
      layout: {
        loading: false,
      },
      uid: action.challenge.uid,
      common: DataMapper.getFormOf({
        ...action.challenge.common,
      }),
      metadata: DataMapper.getFormOf({
        ...action.challenge.metadata,
      }),
      ...(collectionForm && { collection: collectionForm }),
      intro: DataMapper.mapTranslatedContentToForm(challengeIntroForm),
    });
  }

  @Action(DeleteChallengeAction)
  deleteChallengeAction(
    ctx: StateContext<ChallengeStateModel>,
    action: DeleteChallengeAction,
  ) {
    action.challenge.assets.forEach((assetUid) => {
      this.assetRepository.delete({
        uid: assetUid,
      });
    });
    this.challengeRepository.delete(action.challenge);
    ctx.setState(
      patch<ChallengeStateModel>({
        list: patch({
          items: removeItem<Challenge>(
            (challenge) => challenge.uid === action.challenge.uid,
          ),
        }),
      }),
    );
    if (action.disableSavingScenario) {
      return;
    }
    ctx.dispatch(new SaveScenarioAction(true));
  }

  @Action(DeleteChallengesAction)
  deleteChallengesAction(
    ctx: StateContext<ChallengeStateModel>,
    action: DeleteChallengesAction,
  ) {
    action.challenges.forEach((challenge) => {
      challenge.assets.forEach((assetUid) => {
        this.assetRepository.delete({
          uid: assetUid,
        });
      });
      this.challengeRepository.delete(challenge);
      ctx.setState(
        patch<ChallengeStateModel>({
          list: patch({
            items: removeItem<Challenge>(
              (existingChallenge) => existingChallenge.uid === challenge.uid,
            ),
          }),
        }),
      );
    });
    ctx.dispatch(new SaveScenarioAction(true));
  }

  @Action(ImportChallengesAction)
  importChallengesAction(
    ctx: StateContext<ChallengeStateModel>,
    action: ImportChallengesAction,
  ) {
    this.setLoadingState(ctx, true);
    const toast = this.toastService.loading('Importing challenges...');

    return from(action.challenges).pipe(
      mergeMap((challenge) =>
        this.assetService.getAssets(challenge).pipe(
          mergeMap((assets) => from(assets)),
          mergeMap((asset) =>
            from(this.assetService.copyAsset(asset)).pipe(
              catchError((error) => {
                console.error('Error copying asset:', error);
                return of(null); // Continue with other assets even if one fails
              }),
            ),
          ),
          toArray(),
          mergeMap((newAssets) =>
            of({
              ...challenge,
              createdAt: new Date(),
              updatedAt: new Date(),
              uid: GuidUtils.generateUuid(),
              assets: newAssets
                .filter((a): a is NonNullable<typeof a> => a !== null)
                .map((a) => a.uid),
            }),
          ),
          catchError((error) => {
            this.toastService.error('Error getting assets for challenge');
            return EMPTY;
          }),
        ),
      ),
      toArray(),
      tap((allResults) => {
        // Update the state only with successful challenge creations
        if (allResults.every((result) => result !== null)) {
          ctx.setState(
            patch<ChallengeStateModel>({
              list: patch({
                items: append(allResults as Challenge[]),
              }),
              layout: patch({
                loading: false,
              }),
            }),
          );
        }
        toast.close();
        this.toastService.success('Challenges imported successfully');
        this.setLoadingState(ctx, false);
      }),
    );
  }

  private setLoadingState(
    ctx: StateContext<ChallengeStateModel>,
    isLoading: boolean,
  ) {
    const state = ctx.getState();
    ctx.patchState({ layout: { ...state.layout, loading: isLoading } });
  }
}
