import { Injectable, inject } from '@angular/core';
import {
  CommonRepositoryAbstract,
  FileUtils,
  MissionFormDataType,
  MissionIntroForm,
  MissionOutroForm,
  SpecificKeys,
} from '@freddy/common';
import * as geofire from 'geofire-common';
import { GuidUtils } from '../../../core/utils/guid.utils';
import { TenantService } from '../../../core/auth/services/tenant.service';
import {
  ArMissionModel,
  Mission,
  MissionTypeEnum,
  SlidingPuzzleMissionModel,
  TranslatedContent,
} from '@freddy/models';
import { AssetService } from '../../../core/assets/asset.service';
import { Locale } from 'locale-enum';
import { Firestore } from '@angular/fire/firestore';
import { firstValueFrom } from 'rxjs';
import { AssetRepository } from '../../../core/assets/asset.repository';

type IntroOutroKeys = SpecificKeys<MissionFormDataType, 'intro' | 'outro'>;

@Injectable({
  providedIn: 'root',
})
export class MissionRepository extends CommonRepositoryAbstract<Mission> {
  private readonly assetService = inject(AssetService);
  private readonly assetRepository = inject(AssetRepository);
  private readonly tenantService = inject(TenantService);

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  private static DOC_PATH = 'missions';

  async createMission(formDataMission: MissionFormDataType): Promise<Mission> {
    const uploadedAssets = await this.uploadMissionAssets(formDataMission);
    const mission: Mission = {
      ...this.mapper(formDataMission, uploadedAssets),
      organization: this.tenantService.currentOrganizationSlug,
    };

    return firstValueFrom(this.create(mission));
  }

  async updateMission(formDataMission: MissionFormDataType): Promise<Mission> {
    const uploadedAssets = await this.uploadMissionAssets(formDataMission);
    const mission = this.mapper(formDataMission, uploadedAssets);
    await firstValueFrom(this.update(mission));
    return mission;
  }

  async uploadMissionAssets(
    formDataMission: MissionFormDataType,
  ): Promise<string[]> {
    const uploadedAssets: string[] = [];
    if (formDataMission.uid) {
      const previousChallenge = await firstValueFrom(
        this.get(formDataMission.uid),
      ).catch(() => {});
      previousChallenge?.assets.forEach((assetUid) => {
        this.assetRepository.delete({
          uid: assetUid,
        });
      });
    }
    if (formDataMission.metadata.missionType === MissionTypeEnum.AR) {
      // Handle AR mission uploads
      const image = formDataMission.metadata[MissionTypeEnum.AR]?.image;
      if (image && FileUtils.isBase64Image(image)) {
        const uploadedPath = await this.assetService.uploadAndCreateAsset(
          FileUtils.convertBase64ToFile(image, 'ar_image.png'),
          { type: 'AR' },
        );
        formDataMission.metadata[MissionTypeEnum.AR]!.image = uploadedPath.path;
        uploadedAssets.push(uploadedPath.uid);
      }
    }
    if (
      formDataMission.metadata.missionType === MissionTypeEnum.SLIDING_PUZZLE
    ) {
      // Handle Sliding puzzle mission uploads
      const image =
        formDataMission.metadata[MissionTypeEnum.SLIDING_PUZZLE]?.image;
      if (image && FileUtils.isBase64Image(image)) {
        const uploadedPath = await this.assetService.uploadAndCreateAsset(
          FileUtils.convertBase64ToFile(image, 'puzzle_image.png'),
          { type: 'PUZZLE' },
        );
        formDataMission.metadata[MissionTypeEnum.SLIDING_PUZZLE]!.image =
          uploadedPath.path;
        uploadedAssets.push(uploadedPath.uid);
      }
    }
    // Handle intro and outro uploads
    for (const type of ['intro', 'outro'] as IntroOutroKeys[]) {
      const content: TranslatedContent<MissionIntroForm | MissionOutroForm> =
        formDataMission[type];
      for (const locale in content) {
        const missionContent = content[
          locale as keyof TranslatedContent<never>
        ] as MissionIntroForm;
        if (missionContent) {
          for (const file of missionContent.images ?? []) {
            const uploadedPath = await this.assetService.uploadAndCreateAsset(
              file,
              { type, locale: locale },
            );
            uploadedAssets.push(uploadedPath.uid);
          }
        }
      }
    }
    return uploadedAssets;
  }

  private getAssetIdFromPath(path: string): string | null {
    return path?.split('/').pop() || null;
  }

  async cloneMission(mission: Mission): Promise<Mission> {
    const newUid = GuidUtils.generateUuid();

    // Create a mapping of original asset IDs to cloned assets
    const assetMapping = new Map<string, { uid: string; path: string }>();

    // Clone all assets and build the mapping
    await Promise.all(
      mission.assets.map(async (assetId) => {
        const clonedAsset = await this.assetService.copyAssetById(assetId);
        assetMapping.set(assetId, {
          uid: clonedAsset.uid,
          path: clonedAsset.path,
        });
      }),
    );

    // Create the base cloned mission with updated asset references
    const clonedMission: Mission = {
      ...mission,
      uid: newUid,
      assets: mission.assets.map(
        (assetId) => assetMapping.get(assetId)?.uid || assetId,
      ),
      updatedAt: new Date(),
    };

    // Handle special metadata for different mission types
    if (clonedMission.metadata) {
      if (
        clonedMission.metadata.missionType === MissionTypeEnum.AR &&
        clonedMission.metadata[MissionTypeEnum.AR]
      ) {
        const arModel = clonedMission.metadata[
          MissionTypeEnum.AR
        ] as ArMissionModel;
        if (arModel.image) {
          const assetId = this.getAssetIdFromPath(arModel.image);
          if (assetId) {
            const newAsset = assetMapping.get(assetId);
            if (newAsset) {
              arModel.image = newAsset.path;
            }
          }
        }
      }

      if (
        clonedMission.metadata.missionType === MissionTypeEnum.SLIDING_PUZZLE &&
        clonedMission.metadata[MissionTypeEnum.SLIDING_PUZZLE]
      ) {
        const puzzleModel = clonedMission.metadata[
          MissionTypeEnum.SLIDING_PUZZLE
        ] as SlidingPuzzleMissionModel;
        if (puzzleModel.image) {
          const assetId = this.getAssetIdFromPath(puzzleModel.image);
          if (assetId) {
            const newAsset = assetMapping.get(assetId);
            if (newAsset) {
              puzzleModel.image = newAsset.path;
            }
          }
        }
      }
    }

    return clonedMission;
  }

  protected getDocPath(): string {
    return (
      this.tenantService.getOrganizationPrefixPath() +
      MissionRepository.DOC_PATH
    );
  }

  private mapper(
    formDataMission: MissionFormDataType,
    uploadedAssets: string[],
  ): Mission {
    return {
      uid: formDataMission.uid ?? GuidUtils.generateUuid(),
      assets: uploadedAssets,
      intro: this.removeImagesFromTranslatedContent<MissionIntroForm>(
        formDataMission.intro,
      ),
      outro: this.removeImagesFromTranslatedContent<MissionOutroForm>(
        formDataMission.outro,
      ),
      common: {
        ...formDataMission.common,
        ...(formDataMission.common.location && {
          geoHash: geofire.geohashForLocation([
            formDataMission.common.location.lon ?? 0,
            formDataMission.common.location.lat ?? 0,
          ]),
        }),
      },
      metadata: {
        ...formDataMission.metadata,
      },
      updatedAt: new Date(),
    };
  }

  private removeImagesFromTranslatedContent<
    T = MissionOutroForm | MissionIntroForm,
  >(content: TranslatedContent<T>): TranslatedContent<Omit<T, 'images'>> {
    const result: TranslatedContent<Omit<T, 'images'>> = {};
    for (const key in content) {
      if (Object.hasOwn(content, key)) {
        // @ts-ignore
        const { images, ...rest } = content[key as Locale]!;
        result[key as Locale] = rest;
      }
    }
    return result;
  }
}
