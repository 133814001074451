import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Select } from '@ngxs/store';
import { LayoutState } from './core/layout/store/layout.store';
import { Observable } from 'rxjs';
import { UpdateService } from 'common';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, AsyncPipe],
})
export class AppComponent {
  private readonly updateService = inject(UpdateService);

  ngAfterViewInit(): void {
    const splash = document.querySelector('.splash-screen');
    setTimeout(() => {
      splash?.classList.add('hide-splash');
    }, 2000);
  }

  @Select(LayoutState.appLoading)
  loading$!: Observable<boolean>;
}
