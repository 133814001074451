import { Challenge } from '@freddy/models';
import { ChallengeQuery } from '../interfaces/challengeQuery';

export class SaveChallengeAction {
  static readonly type = '[NewChallenge] Create a new challenge';

  constructor() {}
}

export class SaveScenarioChallengeAction {
  static readonly type = '[NewChallenge] Save a challenge for a scenario';

  constructor() {}
}

export class ResetChallengeAction {
  static readonly type = '[NewChallenge] Reset challenge';

  constructor() {}
}

export class ChallengeCreatedAction {
  static readonly type = '[NewChallenge] Challenge has been created';

  constructor(public readonly challenge: Challenge) {}
}

export class ChallengeUpdatedAction {
  static readonly type = '[NewChallenge] Challenge has been updated';

  constructor(public readonly challenge: Challenge) {}
}

export class NewChallengeAction {
  static readonly type = '[NewChallenge] New challenge';
}

export class EditChallengeAction {
  static readonly type = '[EditChallenge] Edit challenge';

  constructor(public readonly challenge: Challenge) {}
}

export class DeleteChallengeAction {
  static readonly type = '[EditChallenge] Delete scenario challenge';

  constructor(
    public readonly challenge: Challenge,
    public readonly disableSavingScenario?: boolean,
  ) {}
}

export class DeleteChallengesAction {
  static readonly type = '[EditChallenge] Delete selected scenario challenges';

  constructor(public readonly challenges: Challenge[]) {}
}

export class ListChallengesAction {
  static readonly type = '[ListChallenges] List challenges';
  constructor(public readonly query: ChallengeQuery) {}
}

export class ImportChallengesAction {
  static readonly type = '[ImportChallenges] Import challenges';
  constructor(public readonly challenges: Challenge[]) {}
}
