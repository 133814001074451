import { ScenarioQueryParam } from '../repository/scenario.query.param';
import { Scenario } from '@freddy/models';
import { GeoPoint } from 'common';

export class SaveScenarioAction {
  static readonly type = '[Scenario] Save a scenario';

  constructor(public readonly silently?: boolean) {}
}

export class ListScenariosAction {
  static readonly type = '[Scenario] List scenarios';

  constructor(public readonly query: ScenarioQueryParam) {}
}

export class EditScenarioAction {
  static readonly type = '[Scenario] Edit scenario';
  constructor(public readonly scenario: Scenario) {}
}

export class CloneScenarioAction {
  static readonly type = '[Scenario] Clone scenario';
  constructor(public readonly scenario: Scenario) {}
}

export class DeleteScenarioAction {
  static readonly type = '[Scenario] Delete scenario';
  constructor(public readonly scenario: Scenario) {}
}

export class FetchAndEditScenarioAction {
  static readonly type = '[Scenario] Fetch scenario';
  constructor(public readonly scenarioUid: string) {}
}

export class ResetScenarioFormAction {
  static readonly type = '[Scenario] Reset scenario form';
  constructor() {}
}

export class SetDefaultMissionsOrder {
  static readonly type = '[Scenario] Set default missions order';
  constructor() {}
}

export class GenerateRoutesForTeam {
  static readonly type = '[Scenario] Generate routes for a single team';
  constructor(public readonly index: number) {}
}

export class UpdateMissionOrder {
  static readonly type = '[Scenario] Update mission order';
  constructor(
    public readonly index: number,
    public readonly missionsOrder: string[],
  ) {}
}

export class DeleteTempMarker {
  static readonly type = '[Scenario] Delete temp marker';
  constructor(public readonly geoPoint: GeoPoint) {}
}

export class NewScenarioAction {
  static readonly type = '[Scenario] New scenario';
  constructor() {}
}

// New actions for Typesense search
export class SearchScenariosAction {
  static readonly type = '[Scenario] Search scenarios';
  constructor(
    public readonly searchTerm: string,
    public readonly page: number = 1,
    public readonly pageSize: number = 10,
  ) {}
}

export class SearchScenariosSuccessAction {
  static readonly type = '[Scenario] Search scenarios success';
  constructor(
    public readonly scenarios: Scenario[],
    public readonly totalResults: number,
  ) {}
}

export class SearchScenariosFailureAction {
  static readonly type = '[Scenario] Search scenarios failure';
  constructor(public readonly error: any) {}
}

export class NextScenarioPageAction {
  static readonly type = '[Scenario] Next page';
}

export class PrevScenarioPageAction {
  static readonly type = '[Scenario] Previous page';
}
