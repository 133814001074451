import { Injectable } from '@angular/core';
import { GeoUtils } from '../../../core/utils/geo.utils';
import { Scenario } from '@freddy/models';
import { GeoPoint } from '@freddy/common';

@Injectable({
  providedIn: 'root',
})
export class MissionsService {
  constructor() {}

  public getScenarioRouting(scenario: Scenario, nbPlayers: number): string[][] {
    const getMissionsUid = (point: GeoPoint) => {
      return (
        scenario.missions.find((m) => m.common.location === point)?.uid ?? ''
      );
    };

    const missionsLocations = scenario.missions
      .map((mission) => mission.common.location)
      .filter((i): i is GeoPoint => !!i);
    const missionsOrders = GeoUtils.getShortestPaths(
      nbPlayers,
      missionsLocations,
    );

    return missionsOrders.map((row) =>
      row.map((value) => getMissionsUid(value)),
    );
  }

  public getScenarioRoutingForASingleTeam(scenario: Scenario): string[] {
    const missionsLocations = scenario.missions
      .map((mission) => mission.common.location)
      .filter((i): i is GeoPoint => !!i);
    const missionsOrder = GeoUtils.getShortestPaths(1, missionsLocations)[0];

    return missionsOrder.map(
      (point) =>
        scenario.missions.find((m) => m.common.location === point)?.uid ?? '',
    );
  }
}
