import { Mission } from '@freddy/models';
import { GeoPoint } from 'common';

export class SaveMissionAction {
  static readonly type = '[NewMission] Save mission';

  constructor() {}
}

export class MissionCreatedAction {
  static readonly type = '[Mission] Mission has been created';

  constructor(public readonly mission: Mission) {}
}

export class MissionUpdatedAction {
  static readonly type = '[Mission] Mission has been updated';

  constructor(public readonly mission: Mission) {}
}

export class ResetMissionFormAction {
  static readonly type = '[Mission] Reset mission form';
}

export class NewMissionAction {
  static readonly type = '[Mission] Open mission Panel';
}

export class EditMissionAction {
  static readonly type = '[EditMission] Edit mission';

  constructor(public readonly mission: Mission) {}
}

export class UpdateMissionPositionAction {
  static readonly type = '[EditMission] Update mission position';
  constructor(
    public readonly missionUid: string,
    public readonly coordinates: GeoPoint,
  ) {}
}

export class DeleteMissionAction {
  static readonly type = '[EditMission] Delete mission';

  constructor(
    public readonly mission: Mission,
    public readonly disableSavingScenario?: boolean,
  ) {}
}

export class EditMissionByIdAction {
  static readonly type = '[EditMissionId] Edit mission by id';

  constructor(public readonly missionId: string) {}
}

export class GoToMissionList {
  static readonly type = '[Mission] Go to mission list';
}

export class RemoveOutro {
  static readonly type = '[Mission] Remove outro';
}
