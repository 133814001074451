import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, from, finalize } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { Auth, authState, User, IdTokenResult } from '@angular/fire/auth';
import { TenantService } from '../services/tenant.service';
import { AuthService } from '../services/auth.service';
import { Store } from '@ngxs/store';
import { AppLoaderAction } from '../../layout/actions/layout.actions';

@Injectable({ providedIn: 'root' })
export class DefaultGuard {
  private auth = inject(Auth);
  private router = inject(Router);
  private tenantService = inject(TenantService);
  private authService = inject(AuthService);
  private store = inject(Store);


  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    console.log('DefaultGuard.canActivate');
    this.store.dispatch(new AppLoaderAction(true));

    const { token, tenantId, organization } = this.extractRouteParams(route);

    if (organization) {
      this.tenantService.currentOrganizationSlug = organization;
    }

    return this.handleAuthentication(token, tenantId).pipe(
      catchError(this.handleError.bind(this)),
      finalize(() => this.store.dispatch(new AppLoaderAction(false))),
    );
  }

  private extractRouteParams(route: ActivatedRouteSnapshot) {
    return {
      token: route.queryParamMap.get('token'),
      tenantId: route.queryParamMap.get('tenantId'),
      organization: route.queryParamMap.get('organization'),
    };
  }

  private handleAuthentication(
    token: string | null,
    tenantId: string | null,
  ): Observable<boolean> {
    return token
      ? this.signInWithToken(token, tenantId)
      : this.checkExistingAuthState();
  }

  private signInWithToken(
    token: string,
    tenantId: string | null,
  ): Observable<boolean> {
    return this.authService
      .signUnWithCustomToken(token, tenantId)
      .pipe(switchMap(() => this.checkExistingAuthState()));
  }

  private checkExistingAuthState(): Observable<boolean> {
    return authState(this.auth).pipe(
      switchMap((user) =>
        user ? this.validateUser(user) : this.handleUnauthenticated(),
      ),
    );
  }

  private validateUser(user: User): Observable<boolean> {
    return from(user.getIdTokenResult(true)).pipe(
      switchMap((idTokenResult) =>
        this.processIdTokenResult(idTokenResult, user),
      ),
    );
  }

  private processIdTokenResult(
    idTokenResult: IdTokenResult,
    user: User,
  ): Observable<boolean> {
    const isCustomAuth =
      idTokenResult.claims['firebase']?.['sign_in_provider'] === 'custom';
    const hasRequiredClaims = this.hasRequiredClaims(idTokenResult.claims);

    if (!hasRequiredClaims) {
      return this.handleUnauthorized();
    }

    if (!isCustomAuth && !user.emailVerified) {
      return this.handleUnverifiedEmail();
    }

    return of(true);
  }

  private hasRequiredClaims(claims: { [key: string]: any }): boolean {
    return ['admin', 'partner', 'manager', 'member', 'evaluator'].some(
      (role) => claims[role],
    );
  }

  private handleUnauthenticated(): Observable<boolean> {
    this.navigateTo('sign-in');
    return of(false);
  }

  private handleUnauthorized(): Observable<boolean> {
    this.navigateTo('not-authorized');
    return of(false);
  }

  private handleUnverifiedEmail(): Observable<boolean> {
    this.navigateTo('verify-email');
    return of(false);
  }

  private handleError(error: any): Observable<boolean> {
    console.error('Authentication error:', error);
    this.navigateTo('sign-in');
    return of(false);
  }

  private navigateTo(path: string): void {
    this.router.navigate([
      this.tenantService.currentOrganizationSlug,
      'auth',
      path,
    ]);
  }
}
