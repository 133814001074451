import { Injectable, inject } from '@angular/core';
import { CommonRepositoryAbstract } from '@freddy/common';
import { TenantService } from '../../../core/auth/services/tenant.service';
import { Collection } from '@freddy/models';
import { AssetService } from '../../../core/assets/asset.service';
import { Firestore } from '@angular/fire/firestore';
import { AssetRepository } from '../../../core/assets/asset.repository';
import { RepositoryOptions } from '../../../../../../common/src/lib';

@Injectable({
  providedIn: 'root',
})
export class CollectionRepository extends CommonRepositoryAbstract<Collection> {
  private readonly assetService = inject(AssetService);
  private readonly assetRepository = inject(AssetRepository);
  private readonly tenantService = inject(TenantService);

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  private static DOC_PATH = 'collections';

  protected getDocPath(): string {
    return (
      this.tenantService.getOrganizationPrefixPath() +
      CollectionRepository.DOC_PATH
    );
  }

  public override getRepositoryOptions(): RepositoryOptions {
    return {
      pageSize: 200,
    };
  }
}
