import { LayoutState } from './app/core/layout/store/layout.store';
import * as Sentry from '@sentry/angular';
import { browserTracingIntegration } from '@sentry/angular';
import { environment } from './environments/environment';
import {
  createTracingProviders,
  NgxsSentryBreadcrumbsService,
} from '@freddy/common';
import { CoreModule } from './app/core/core.module';
import { AppRoutingModule } from './app/app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  importProvidersFrom,
  inject,
  isDevMode,
  provideAppInitializer,
} from '@angular/core';
import { SharedModule } from './app/shared/shared.module';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideStore } from '@ngxs/store';
import { withNgxsFormPlugin } from '@ngxs/form-plugin';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { withNgxsLoggerPlugin } from '@ngxs/logger-plugin';
import { AuthenticationState } from './app/core/auth/store/authentication.store';
import { OrganizationState } from './app/features/organization/store/organization.store';
import { ChallengeState } from './app/features/challenge/store/challenge.store';
import { ChatState } from './app/features/chat/store/chat.store';
import { ScenarioState } from './app/features/scenario/store/scenario.store';
import { withNgxsRouterPlugin } from '@ngxs/router-plugin';

import { version as appVersion } from '../../../package.json';
// @ts-ignore
window['FIREBASE_APPCHECK_DEBUG_TOKEN'] = false;

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
  ],
  release: environment.version,
  environment: environment.name,
  // Performance Monitoring
  tracesSampleRate: environment.sentry.tracesSampleRate, //  Capture 100% of the transactions
  // Session Replay

  replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: NgxsSentryBreadcrumbsService,
      useClass: NgxsSentryBreadcrumbsService,
    },
    provideAppInitializer(() => {
      inject(NgxsSentryBreadcrumbsService);
    }),
    provideStore(
      [
        AuthenticationState,
        LayoutState,
        OrganizationState,
        ChallengeState,
        ChatState,
        ScenarioState,
      ],
      {
        developmentMode: environment.production,
      },
      withNgxsFormPlugin(),
      withNgxsReduxDevtoolsPlugin({
        name: 'Freddy Admin',
        disabled: environment.production,
      }),
      withNgxsRouterPlugin(),
      withNgxsLoggerPlugin({
        filter: (action, state) =>
          !action.constructor.type?.includes('@@') &&
          !action?.constructor.type?.includes('[Forms]') &&
          !action?.constructor.type?.includes('[Router]'),
      }),
    ),
    importProvidersFrom(
      CoreModule,
      AppRoutingModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      SharedModule,
    ),
    ...createTracingProviders(environment, appVersion),
  ],
}).catch((err) => console.error(err));
