import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';

export abstract class RouterUtils {
  static getActivatedRoute(router: Router) {
    let route = router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
